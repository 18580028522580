import * as React from "react"

// styles
import "../styles/index.css"
import "../styles/header.css"
import "../styles/banner.css"
import "../styles/footer.css"
import "../styles/sbanner.css"
import "../styles/services.css"
import "../styles/servicesTemp.css"
import "../styles/contact.css"
//import "../styles/careers.css"
import "../styles/serviceCard.css"
import "../styles/ourStory.css"
import "../styles/blog.css"


// components
import Header from "../components/Header.js";
import Banner from "../components/Banner.js"
import Footer from "../components/Footer.js";
import ServiceCard from "../components/ServiceCard.js";

//icons
import heartHome from "../images/heartHome.png"
import group from "../images/group.png"
import community from "../images/community.png"

const IndexPage = () => {
 
  return (
  

    <main id="home">
     

      <title>Co-op Homecare | Home</title>
      <Header/>
      <Banner/>
     
      <section className="center-column" id="message-parent">
        <h1> A Message From Our Care Professionals: </h1>
        <p> As members of a worker-owned cooperative, not only do we provide the support that our clients need, but we also own and operate the business together. We believe that our care services are only as good as our care professionals. Our cooperative provides us with sustainable and fulfilling careers which translate into lasting and supportive relationships with our clients and their families. </p>
      </section>

      <section className="center-column" id="services-parent">
          <h1> Our Services </h1>
          <div className="center-column-to-row" id="services-content">
            <ServiceCard title="Personal Care" description="We assist with various activities surrounding daily living"/>
            <ServiceCard title="Domestic Care" description="We provide in home assistance for household activities"/>
          </div>
      </section>

      <section className="center-column" id="mission">
        <h1> Our Mission </h1>
        <div className="mission-card center-column-to-row ">
          <img src={heartHome} alt="Heart in Home Icon"></img>
          <div>
            <h2>Committed Care Services</h2>
            <p>
              Co-op Home Care contributes a crucial community service to the elderly and disabled. Care services from a cooperative means happier and more committed caregivers, more direct lines of communication, and contribution to a mission (not profit) driven business grounded in the community, and controlled by community members.
            </p>
          </div>
        </div>
        <div className="mission-card center-column-to-row">
          <img src={group} alt="Group Icon"></img>
          <div>
            <h2>Sustainable & Fulfilling Job Opportunities </h2>
            <p>
              As a worker-owned cooperative, Co-op Home Care provides a more equitable workplace where its members democratically control the business. Caregivers can influence business decisions that affect their lives and livelihood. Additionally, we offer competitive wages, positive work conditions, and profit sharing for all our members. 
            </p>
          </div>
        </div>
        <div className="mission-card center-column-to-row">
          <img src={community} alt="Community Icon"></img>
          <div>
            <h2>Commitment to the Community</h2>
            <p>
            Guided by cooperative principles, we are dedicated to contributing to the sustainable development of our community by providing well paying and sustainable jobs as well as quality care services in Yolo County.
            </p>
          </div>
        </div>
      </section>
    
      <Footer/>
    </main>
  )
}

export default IndexPage
